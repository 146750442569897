import React, { useState, useEffect, useRef, useCallback } from 'react'
import Context from './context'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
    UPDATE_CART,
    ADD_CATALOG_ITEM,
    GET_TAX_RATE,
    GET_WEB_USER_CONTACTS,
    GET_ITEM_PRICE,
    GET_ITEM_AVAILABILITY,
    GET_SHOPPING_LISTS,
    UPDATE_SHOPPING_LISTS,
    GET_PRICE_REASONS,
    GET_ITEMS_BY_ID,
    QUERY_STOCK_AVAILABILITY_BATCH,
    GET_HOMEPAGE,
    GET_ALERTS,
    IMPERSONATOR_HISTORY,
} from './providerGQL'
import {
    getRidOf__typename,
    distinct,
    useDebounceValue,
    getRidOf__typename_generic
} from '../pageComponents/_common/helpers/generalHelperFunctions'
import { GET_ITEM_CUSTOMER_PART_NUMBERS, GET_ITEM_SOURCE_LOCATIONS } from './gqlQueries/gqlItemQueries'
import { AIRLINE_ENGINEER_USER, GUEST, IMPERSONATOR_USER, WEB_USER } from '../pageComponents/_common/constants/UserTypeConstants'
import { useLocation, useNavigate } from 'react-router'
import { useAuth } from 'react-oidc-context'
import { useAirlineFetch } from 'setup/REST'

const defaultOrderNoteAreas = ['Print Order Acknowledgements', 'Order Entry'];

export default function ContextProvider({ children }) {
	const auth = useAuth();
	
    const didMountRef = useRef(false);
    const lastShoppingCartPayload = useRef(null);
    const [shoppingCart, setShoppingCart] = useState(null);
    const debouncedCart = useDebounceValue(shoppingCart, 1000);
    const [orderNotes, setOrderNotes] = useState('');
    const [orderNoteAreas, setOrderNoteAreas] = useState(defaultOrderNoteAreas);
    const [orderNote, setOrderNote] = useState('');
    const [shoppingCartPricing, setShoppingCartPricing] = useState({ state: 'stable', subTotal: '--', tariff: '--' });
    const [attachments, setAttachments] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [impersonatorHistory, setImpersonatorHistory] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    function handleSetUserInfo(newUserInfo) {
		setUserInfo(newUserInfo ? {
			...newUserInfo,
			isAirlineEmployee: newUserInfo?.role === AIRLINE_ENGINEER_USER || newUserInfo?.role === IMPERSONATOR_USER,
			isAirlineEngineerUser: newUserInfo?.role === AIRLINE_ENGINEER_USER,
			isImpersonatorUser: newUserInfo?.role === IMPERSONATOR_USER,
			isWebUser: newUserInfo?.role === WEB_USER,
			permissions: newUserInfo?.permissions || []
		} : null);
	}
    const [impersonatedCompanyInfo, setImpersonatedCompanyInfo] = useState(null);

    const [topAlert, setTopAlert] = useState({ show: false, message: '' });
    const [itemPrices, setItemPrices] = useState([]);
    const [itemAvailabilities, setItemAvailabilities] = useState([]);
    const [stockAvailabilities, setStockAvailabilities] = useState([]);
    const [itemDetails, setItemDetails] = useState([]);
    const [customerPartNumbers, setCustomerPartNumbers] = useState([]);
    const [sourceLocations, setSourceLocations] = useState([]);
    const [shoppingLists, setShoppingLists] = useState([]);
    const [shoppingCartPayload, setShoppingCartPayload] = useState(null);
    const debouncedCartPayload = useDebounceValue(shoppingCartPayload);
    const [webUserContacts, setWebUserContacts] = useState([]);
    const [editPriceReasonCodes, setEditPriceReasonCodes] = useState([]);
    const [serviceParts, setServiceParts] = useState([]);
    const [cartLoading, setCartLoading] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [passwordResetEmail, setPasswordResetEmail] = useState('');
    const [homepage, setHomepage] = useState([]);
    const [alert, setAlert] = useState(null);
    const [isProspect, setIsProspect] = useState(null);
    const [userLoading, setUserLoading] = useState(true);
    const [noteAreas, setNoteAreas] = useState([]);
    const [searchSortType, setSearchSortType] = useState('relevancy');
	
    const navAwayRoutes = ['/cart', '/checkout', '/create-quote'];
    const partialNavAwayRoutes = ['/account'];
    const currentPath = location.pathname;
    const resetOnImpersonate = navAwayRoutes.includes(currentPath) || partialNavAwayRoutes.some(route => currentPath.includes(route));
	
	const { airlinePost } = useAirlineFetch();
	
	function getImpersonatedCompanyInfo() {
		airlinePost("/api/Authorization/ImpUserInfo") 
			.then((res) => res.json())
			.then((res) => { 
				setImpersonatedCompanyInfo(res);
		});
	}

	/* Shim userLoading from auth */
	useEffect(() => {
		setUserLoading(auth.isLoading);
	},[auth.isLoading]);

    useEffect(() => {
        if (!didMountRef.current) { // If page refreshed or first loaded, check to see if any tokens exist and update Context accordingly
            getHomepage();
            getAlert();
			retrieveShoppingCart();
        }
        didMountRef.current = true
    }, []);

    const [getHomepage] = useLazyQuery(GET_HOMEPAGE, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const home = JSON.parse(JSON.stringify(data.getMarketingData))
            setHomepage(home.sort((a, b) => a.sort > b.sort ? 1 : -1))
        }
    })

    const [getAlert] = useLazyQuery(GET_ALERTS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setAlert(data.websiteAlert)
        }
    })

    useEffect(() => {
        setCartLoading(true)
        setTimeout(() => setCartLoading(false), 1200)
    }, [shoppingCart])

    useEffect(() => {
		if(userInfo?.isAirlineEmployee) {
			getPriceReasons();
		}
    }, [userInfo])

    useEffect(() => {
        if (userInfo?.isAirlineEmployee) {
            getImpersonatorHistory()
        }

    }, [userInfo?.isAirlineEmployee]);

    const [getPriceReasons] = useLazyQuery(GET_PRICE_REASONS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setEditPriceReasonCodes(data.priceReasons.map(({ __typename, ...rest }) => rest))
        }
    })

    const [getImpersonatorHistory] = useLazyQuery(IMPERSONATOR_HISTORY, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            setImpersonatorHistory(data.getImpersonatorHistory)
        }
    })

    const [updateTaxesApiCall] = useLazyQuery(GET_TAX_RATE, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            console.log('got taxes ->', data)
        }
    })

    const updateTaxes = (zipcode, shipToId) => {
        updateTaxesApiCall({
            variables: {
                anonymousCartToken: localStorage.getItem('shoppingCartToken'),
                shipToId: shipToId,
                zipcode: zipcode
            }
        })
    }

    const [handleGetItemPrices] = useLazyQuery(GET_ITEM_PRICE, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setItemPrices([...data.getItemPrices, ...itemPrices].filter(distinct))
        }
    })

    const [handleGetItemAvailabilities] = useLazyQuery(GET_ITEM_AVAILABILITY, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setItemAvailabilities([...data.itemAvailability, ...itemAvailabilities].filter(distinct))
        }
    })

    const [handleGetItemDetails] = useLazyQuery(GET_ITEMS_BY_ID, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setItemDetails([...data.itemDetailsBatch, ...itemDetails].filter(distinct))
        }
    })

    const [handleGetCustomerPartNumbers] = useLazyQuery(GET_ITEM_CUSTOMER_PART_NUMBERS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setCustomerPartNumbers([...data.customerPartNumbersBatch, ...customerPartNumbers].filter(distinct))
        }
    })

    const [handleGetSourceLocations] = useLazyQuery(GET_ITEM_SOURCE_LOCATIONS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setSourceLocations([...data.sourceLocations, ...sourceLocations].filter(distinct))
        }
    })

    const [handleGetStocks, { variables: stockVariables }] = useLazyQuery(QUERY_STOCK_AVAILABILITY_BATCH, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const airlineStockRecords = data.airlineStockBatch
            const factoryStockRecords = data.factoryStockBatch
            const onOrderRecords = data.onOrderBatch

            const stockInfoRecords = stockVariables.invMastUids.map(invMastUid => {
                return {
                    invMastUid: invMastUid,
                    airlineStock: airlineStockRecords?.filter(airlineStock => airlineStock.invMastUid === invMastUid) || [],
                    factoryStock: factoryStockRecords?.find(factoryStock => factoryStock.invMastUid === invMastUid) || null,
                    onOrder: onOrderRecords?.find(o => o.invMastUid === invMastUid) || [],
                    isBeingWatched: onOrderRecords?.find(o => o.invMastUid === invMastUid)?.isBeingWatched || false
                }
            })
            const duplicateInvMasUids = (sa, i, self) => self.findIndex(s => s.invMastUid === sa.invMastUid) === i
            const newStockRecords = [...stockInfoRecords, ...stockAvailabilities].filter(duplicateInvMasUids)

            setStockAvailabilities(newStockRecords)
        }
    })

    function getItemPrices(items) {
        handleGetItemPrices({
            variables: {
                items: items.map(({ invMastUid, quantity }) => ({
                    invMastUid: invMastUid,
                    quantity: quantity !== null && quantity !== undefined ? quantity : 1
                }))
            }
        })
    }

    function getItemAvailabilities(items) {
        handleGetItemAvailabilities({ variables: { invMastUids: items.map(({ invMastUid }) => invMastUid) } })
    }

    function getItemDetails(items) {
        handleGetItemDetails({ variables: { invMastUids: items.map(({ invMastUid }) => invMastUid) } })
    }

    function getCustomerPartNumbers(items) {
        handleGetCustomerPartNumbers({ variables: { invMastUids: items.map(({ invMastUid }) => invMastUid) } })
    }

    function getSourceLocations(items) {
        handleGetSourceLocations({ variables: { invMastUids: items.map(({ invMastUid }) => invMastUid) } })
    }

    function getStocks(items) {
        handleGetStocks({ variables: { invMastUids: items.map(({ invMastUid }) => invMastUid) } })
    }

    const addCustomerPartNumber = newCustomerPartNumber => {
        setCustomerPartNumbers([...customerPartNumbers, newCustomerPartNumber])
    }

    const [getShoppingLists, getShoppingListsState] = useLazyQuery(GET_SHOPPING_LISTS, {
        fetchPolicy: 'no-cache',
        onCompleted: ({ shoppingList }) => {
            setShoppingLists(shoppingList.map(getRidOf__typename))
        }
    })

    const [handleUpdateShoppingList, upsertShoppingListState] = useMutation(UPDATE_SHOPPING_LISTS, {
        fetchPolicy: 'no-cache',
        onCompleted: ({ shoppingListEdit }) => {
            const distinctShoppingLists = (list, idx, self) => self.findIndex(l => l.id === list.id) === idx
            if (!shoppingListEdit) {
                getShoppingLists()
            } else {
                setShoppingLists([getRidOf__typename(shoppingListEdit), ...shoppingLists].filter(distinctShoppingLists))
            }
            return Promise.resolve()
        }
    })

    const upsertShoppingList = (shoppingList) => { // if shoppingList.id === null then this will insert otherwise it will update
        const items = shoppingList.shoppingListItems.map(({ invMastUid, quantity, customerPartNumberId }) => (
            { invMastUid, quantity, customerPartNumberId }
        ))
        return handleUpdateShoppingList({ variables: { shoppingList: { ...shoppingList, shoppingListItems: items } } })
    }

    const [getWebUserContacts, getWebUserContactsState] = useLazyQuery(GET_WEB_USER_CONTACTS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setWebUserContacts(data.webUsers)
        }
    });

	function shimUserInfo() {
		if(auth.isAuthenticated) {
			//This call shims the oidc-context-js implementation into userinfo object, which everything depends on
			//auth.user.profile is the object corresponding to what SSO's userinfo endpoint returns
			const role =  auth.user.profile.UserType === "Employee" 
				? (auth.user.profile.ImpCustIdAw && auth.user.profile.ImpCustIdP21 ? IMPERSONATOR_USER : AIRLINE_ENGINEER_USER) 
				: (auth.user.profile.UserType === "WebUser" ? WEB_USER : GUEST);

			handleSetUserInfo({
				firstName: "(deprecated)",
				lastName: "(deprecated)",
				preferredName: auth.user.profile.sub, 
				companyName: auth.user.profile.ImpCustName || auth.user.profile.CustName,
				companyId: auth.user.profile.ImpCustIdP21 || auth.user.profile.P21CustId,
				webUserId: auth.user.profile.WebUserId,
				role: role,
				isAirlineEmployee: role === IMPERSONATOR_USER || role === AIRLINE_ENGINEER_USER,
				isAirlineEngineerUser: role === AIRLINE_ENGINEER_USER,
				isImpersonatorUser: role === IMPERSONATOR_USER,
				isWebUser: role === WEB_USER,
				permissions: [],
				limits: [],
				__remark: "This object is deprecated. New implementations should use useAuth() hook -> user.profile for determining user info."
			});
		} else {
			handleSetUserInfo(null);
		}
	}

	useEffect(() => {
		if(auth.isAuthenticated && auth.user?.profile !== null) {
			userLoaded();
		}
	}, [auth.user]);

	function getShouldResetStateOnImpersonationChange() {
		return localStorage.getItem("doImpRunOnce") === true | false;
	}

	function setShouldResetStateOnImpersonationChange(isFlagged) {
		localStorage.setItem("doImpRunOnce", isFlagged);
	}

	function userLoaded() {
		console.log("userLoaded");
		if (shoppingCart?.length > 0) {
            mergeShoppingCart(localStorage.getItem('shoppingCartToken'));
        } else {
            retrieveShoppingCart('retrieve');
        }

		//Is this person impersonating?
		if(!!auth.user.profile.ImpCustIdAw) {
			//Load impersonated company info
			getImpersonatedCompanyInfo();
		} else {
			//Clear impersonated company info	
			setImpersonatedCompanyInfo(null);
		}

		// Is this the first run after changing impersonation states?
		if(getShouldResetStateOnImpersonationChange() === true) {
			setShouldResetStateOnImpersonationChange(false);
			localStorage.removeItem('shoppingCartToken');
			setShoppingLists([]);
			setWebUserContacts([]);
			setItemPrices([]);
			setCustomerPartNumbers([]);
		}

		const drift = window.drift || null;
        if (drift && userInfo.role === AIRLINE_ENGINEER_USER) {
            drift?.api?.widget?.hide();
        }

		setCustomerPartNumbers([]);
		setItemPrices([]);
	}

	function userSignedIn() {
		showTopAlert('You have been successfully logged in.');
		window.setTimeout(removeTopAlert, 2000);
	}

	function userSignedOut() {
		console.log("userSignedOut");
		
		if (window.drift?.api) window.drift.api.widget.show();
		emptyCart();
		showTopAlert('You have been logged out.');
		window.setTimeout(removeTopAlert, 2000);

		const keysToRemove = ['userInfo', 'enableAutoLogin', 'apiToken', 'refreshToken', 'imperInfo', 'shoppingCartToken'];
		keysToRemove.forEach(key => localStorage.removeItem(key));
		handleSetUserInfo(null);
		setImpersonatedCompanyInfo(null);
		setShoppingCart(null);
		setItemPrices([]);
		setCustomerPartNumbers([]);
		navigate('/');
	}

	function signIn(returnUrl = null) {
		if(returnUrl) {
			auth.signinRedirect({extraQueryParams: { returnUrl: returnUrl }});
		} else {
			auth.signinRedirect();
		}
	}

	function signOut(returnUrl = null) {
		if(returnUrl) {
			auth.signoutRedirect({extraQueryParams: { returnUrl: returnUrl }});
		} else {
			auth.signoutRedirect();
		}
	}

	function startImpersonation(customerIdAw, returnUrl = null) {
		setShouldResetStateOnImpersonationChange(true);
		auth.signinRedirect({extraQueryParams: { imp: customerIdAw, returnUrl: returnUrl }});
	}

	function cancelImpersonation(returnUrl = null) {
		setShouldResetStateOnImpersonationChange(true);
		//Ask to sign in without an imp token resets impersonation
		signIn(returnUrl);
	}

	//Register login/logout callbacks with the higher level auth provider
	useEffect(shimUserInfo, [auth, auth.user]);
	
	useEffect(() => {
		return auth.events.addUserSignedOut((u) => {
			console.log("User Signed Out Event", u);
			userSignedOut(); //TODO this isnt being called
		});
	}, [auth.events]);

	useEffect(() => {
		return auth.events.addUserSignedIn((u) => {
			userSignedIn(); //Occurs only once, when the user actually signs in and follows the SSO flow.
		});
	}, [auth.events]);

	useEffect(() => {
		return auth.events.addUserLoaded((u) => {
			setUserLoading(false);
		});
	}, [auth.events]);
	
	//auth.events.addAccessTokenExpired(() => showTopAlert("Your session has expired and you need to sign in again."));
	useEffect(() => {
		if(auth && auth.events && auth.events.error) {
			showTopAlert("Error with authentication: " + auth.events.error.message);
		}
		
	}, [auth, auth.events, auth.events.error]);


    function removeTopAlert() {
        setTopAlert({
            show: false,
            message: ''
        });
    }

    function showTopAlert(message) {
        setTopAlert({ show: true, message })
    }
    //Call this after doing a cart update server call to update the local instance of the cart
    function afterCartUpdated(token, cartItems, subtotal, tariff, orderNotes, attachments, noteAreas) {
        const lastCartItems = lastShoppingCartPayload.current
        const cartsMatch = lastCartItems && cartItems.length === lastCartItems.length
        const shouldUpdateState = shoppingCart === null || !lastCartItems ||
            (cartsMatch && !cartItems.find((item, idx) => item.invMastUid !== lastCartItems[idx]?.invMastUid))
        if (shouldUpdateState) {
            localStorage.setItem('shoppingCartToken', token);
            let cartMapping = cartItems.map(({ __typename, ...rest }) => {
                return { ...rest, extraNotes: rest.extraNotes?.map(({ __typename, ...r }) => r), customizations: rest.customizations?.map(c => getRidOf__typename_generic({...c, customizedItem: getRidOf__typename_generic(c?.customizedItem)})) }
            })
            setShoppingCart(cartMapping)
            setOrderNotes(orderNotes)
            setOrderNoteAreas(noteAreas)
            setShoppingCartPricing({ state: 'stable', subTotal: subtotal.toFixed(2), tariff: tariff.toFixed(2) })
            const attachmentsDetyped = attachments.map(({ __typename, ...rest }) => {
                return {
                    ...rest
                }
            })
            setAttachments(attachmentsDetyped)
        }
    }

    const [shoppingCartApiCall] = useMutation(UPDATE_CART, {
        fetchPolicy: 'no-cache',
        onError: (error) => {
            setShowErrorModal(true)
        },
        onCompleted: ({ shoppingCart: { token, action, cartItems, subtotal, tariff, orderNotes, attachments, noteAreas } }) => {
            if (action === 'merge' || action === 'retrieve' || action === 'update' || action === 'quote_to_cart') {
                afterCartUpdated(token, cartItems, subtotal, tariff, orderNotes, attachments, noteAreas || defaultOrderNoteAreas)
            }
        }
    })

    const [addCatalogItemApiCall] = useMutation(ADD_CATALOG_ITEM, {
        fetchPolicy: 'no-cache',
        onCompleted: ({ shoppingCartAddCatalogItem: { token, cartItems, subtotal, tariff, orderNotes } }) => {
            localStorage.setItem('shoppingCartToken', token)
            setShoppingCart(cartItems.map(({ __typename, ...rest }) => {
                return { ...rest, extraNotes: rest.extraNotes?.map(({ __typename, ...r }) => r) }
            }))
            setOrderNotes(orderNotes)
            setShoppingCartPricing({ state: 'stable', subTotal: subtotal.toFixed(2), tariff: tariff.toFixed(2) })
        }
    })

    useEffect(() => {
        if (debouncedCartPayload) {
			 shoppingCartApiCall(debouncedCartPayload);
		 }
    }, [debouncedCartPayload])

    const updateShoppingCart = (cartItems, notes = orderNotes, noteAreas = orderNoteAreas) => {
        setShoppingCart(cartItems) //Needed, or quick cart adds will lose items
        setOrderNotes(notes)
        setOrderNoteAreas(noteAreas)
        lastShoppingCartPayload.current = cartItems
        updateCartWrapper({ actionString: 'update', orderNotes: notes, noteAreas, cartItems })
    }

    const addQuoteToCart = (quoteNumber, quoteLineNumber) => {
        const shoppingCartToken = localStorage.getItem('shoppingCartToken');
        setShoppingCartPricing({ state: 'loading', subTotal: '--', tariff: '--' });
        return shoppingCartApiCall({
            variables: {
                cartInfo: {
                    token: shoppingCartToken,
                    actionString: 'quote_to_cart',
                    quoteNumber: quoteNumber,
                    quoteLineNumber: quoteLineNumber
                }
            }
        })
    }

    const deleteAttachments = (attachments) => {
        updateCartWrapper({ actionString: 'update', orderNotes, noteAreas: orderNoteAreas, cartItems: shoppingCart, attachments })
    }

    const updateCartWrapper = cartInfo => {
		console.log("Current Cart Token:", localStorage.getItem('shoppingCartToken'));
        const shoppingCartToken = localStorage.getItem('shoppingCartToken');
        setShoppingCartPricing({ state: 'loading', subTotal: '--', tariff: '--' });
        setShoppingCartPayload({
            variables: {
                cartInfo: {
                    token: shoppingCartToken,
                    ...cartInfo
                }
            }
        });
    }

    function updateCartWithPromise(cartItems) {
        const shoppingCartToken = localStorage.getItem('shoppingCartToken');
        setShoppingCart(cartItems); //Needed, or quick cart adds will lose items
        setShoppingCartPricing({ state: 'loading', subTotal: '--', tariff: '--' });
        return shoppingCartApiCall({
            variables: {
                cartInfo: {
                    token: shoppingCartToken,
                    actionString: 'update',
                    cartItems: [...cartItems]
                }
            }
        });
    }
    const shoppingCartRef = useRef(shoppingCart);
 
    useEffect(() => {
        shoppingCartRef.current = shoppingCart;
    }, [shoppingCart]);
    
    const addItem = useCallback((item) => {
        updateShoppingCart([...shoppingCartRef.current, item])
    },[shoppingCartRef.current])

    const addItems = (items, orderNotes) => {
        updateShoppingCart([...shoppingCart, ...items], orderNotes)
    }

    const addCatalogItem = ({ itemCatalogUid, quantity }) => {
        const shoppingCartToken = localStorage.getItem('shoppingCartToken')
        setShoppingCartPricing({ state: 'loading', subTotal: '--', tariff: '--' })
        addCatalogItemApiCall({
            variables: {
                catalogItem: {
                    token: shoppingCartToken,
                    itemCatalogUid: itemCatalogUid,
                    quantity: quantity
                }
            }
        })
    }

    function removeItem(itemLocation) {
        const newCart = shoppingCart?.slice() || []
        newCart.splice(itemLocation, 1)
        updateShoppingCart(newCart)
    }

    function moveItem(itemLocation, newLocation) {
        const newCart = shoppingCart.slice() || []
        const movedItem = newCart.splice(itemLocation, 1)
        newCart.splice(newLocation, 0, movedItem[0])
        updateShoppingCart(newCart)
    }

    function splitItem(index, lineCount, lineQuantity) {
        const splitItems = []
        for (let i = 0; i < lineCount; i++) {
            splitItems.push({
                invMastUid: shoppingCart[index].invMastUid,
                quantity: parseInt(lineQuantity),
            })
        }
        const frontCart = shoppingCart?.slice(0, index) || [] // returns cart item before split item
        const backCart = shoppingCart?.slice(index + 1) || [] // returns cart item after split item
        updateShoppingCart([...frontCart, ...splitItems, ...backCart])
    }

    const updateCartItem = (index, newItem) => {
        updateShoppingCart(shoppingCart?.map((item, idx) => idx === index ? newItem : item))
    }

    const updateCartItemField = (index, field, value) => {
        updateShoppingCart(shoppingCart?.map((item, idx) => idx === index ? { ...item, [field]: value } : item))
    }

    const updateOrderNotes = newOrderNotes => {
        setOrderNotes(newOrderNotes)
        updateCartWrapper({ actionString: 'update', orderNotes: newOrderNotes, noteAreas: orderNoteAreas, cartItems: shoppingCart })
    }

    const updateOrderNoteAreas = noteAreas => {
        setOrderNoteAreas(noteAreas);
        updateCartWrapper({ actionString: 'update', noteAreas, orderNotes, cartItems: shoppingCart });
    }

    const saveShoppingCart = () => {
        updateCartWrapper({ actionString: 'save' });
    }
	
    const retrieveShoppingCart = () => {
        lastShoppingCartPayload.current = null
        updateCartWrapper({ actionString: 'retrieve' });
    }

    const mergeShoppingCart = token => {
        lastShoppingCartPayload.current = null
        updateCartWrapper({ actionString: 'merge', token })
    }

    const emptyCart = () => {
        updateShoppingCart(null, '', [])
    }

    function addServicePart(part) {
        setServiceParts({ ...serviceParts, part })
    }

    function removeServicePart(index) {
        setServiceParts(serviceParts?.filter((item, idx) => idx !== index))
    }

    const updateServicePartField = (index, field, value) => {
        setServiceParts(serviceParts?.map((item, idx) => idx === index ? { ...item, [field]: value } : item))
    }

    return (
        <Context.Provider
            value={{
				signIn,
				signOut,
                impersonatedCompanyInfo,
                startImpersonation,
                cancelImpersonation,
                topAlert,
                removeTopAlert,
                userInfo,
                cart: debouncedCart,
                cartPricing: shoppingCartPricing,
                orderNotes,
                orderNoteAreas,
                attachments,
                setAttachments,
                deleteAttachments,
                addItem,
                addItems,
                updateCartWithPromise,
                addCatalogItem,
                removeItem,
                moveItem,
                splitItem,
                emptyCart,
                saveShoppingCart,
                updateTaxes,
                updateOrderNotes,
                updateOrderNoteAreas,
                itemPrices,
                itemAvailabilities,
                stockAvailabilities,
                setStockAvailabilities,
                itemDetails,
                customerPartNumbers,
                sourceLocations,
                getItemPrices,
                setItemPrices,
                getItemAvailabilities,
                getStocks,
                getItemDetails,
                getCustomerPartNumbers,
                addCustomerPartNumber,
                getSourceLocations,
                getShoppingLists,
                getShoppingListsState,
                upsertShoppingList,
                upsertShoppingListState,
                shoppingLists,
                getWebUserContacts,
                getWebUserContactsState,
                webUserContacts,
                editPriceReasonCodes,
                afterCartUpdated,
                updateCartItem,
                updateCartItemField,
                updateShoppingCart,
                setShoppingCart,
                setShoppingCartPricing,
                addQuoteToCart,
                serviceParts,
                addServicePart,
                updateServicePartField,
                removeServicePart,
                cartLoading,
                showErrorModal,
                setShowErrorModal,
                passwordResetEmail,
                setPasswordResetEmail,
                homepage,
                alert,
                setIsProspect,
                isProspect,
                impersonatorHistory,
                userLoading,
                noteAreas,
                setNoteAreas,
                searchSortType,
                setSearchSortType
            }}
        >
            {children}
        </Context.Provider>
    )
}
