import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import Context from '../../../setup/context'
import CopyToClipboard from '../../_common/CopyToClipboard'
import NumberFormat from 'react-number-format'
import AddedModal from '../../SearchResults/uiComponents/addedModal'
import { Grid } from '@mui/material'
import QuantityInput from '../../_common/form/quantityInput'
import AirlineChip from '../../_common/styledComponents/AirlineChip'
import { format as dateFormat } from 'date-fns'
import ItemImage from '../../_common/ItemImage'
import { DetailDivContainer, DetailButtonSmall, DetailDivCard, DetailTextRow, DetailDivCol1, DetailDivCol2, DetailDivCol3, DetailExtendedDescSpan, DetailImgContainer, DetailP1, DetailP2, DetailFullRow } from '../orderQuoteShared'
import { Link } from 'react-router-dom'
import { ShowNoteAlert } from 'styles/alerts'

const TrackingDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 5px;
`
const TrackingP = styled.div`
    display: flex;
    color: grey;
    font-size: 12px !important;
    padding: 0 2px;
    flex-direction: column;
`

const TrackingPAlt = styled.div`
    display: flex;
    color: grey;
    font-size: 15px !important;
    padding: 0 2px;
    flex-direction: column;
`

export default function OrderDetailItem({ item, itemDetails, priceInfo, hidePurchaseButtons }) {
    const [quantity, setQuantity] = useState(1)
    const [showShowAddedToCartModal, setShowAddedToCartModal] = useState(false)
    const context = useContext(Context)

    const trackingCodes = item.trackingNumbers?.map((trackingNumberObject) => {
        return <TrackingDiv key={trackingNumberObject.trackingNumber}>
            {/* we do not have valid tracking # 100% of the time so we mannually build the urls for frontends consumption*/}
            {trackingNumberObject.trackingUrl
                ? <a href={trackingNumberObject.trackingUrl.slice(0, trackingNumberObject.trackingUrl.length - trackingNumberObject.trackingNumber.length) + trackingNumberObject.trackingNumber} target='_blank' rel='noreferrer'>
                    {trackingNumberObject.trackingNumber}
                </a>
                : <span>{trackingNumberObject.carrierName}: {trackingNumberObject.trackingNumber}</span>}
        </TrackingDiv>
    })

    function handleAddedToCart() {
        setShowAddedToCartModal(false)
    }

    const handleAddToCart = () => {
        context.addItem({
            invMastUid: item.invMastUid,
            quantity: parseInt(quantity, 10),
            itemUnitPriceOverride: null,
            customerPartNumberId: item.customerPartNumberId
        })
        setShowAddedToCartModal(true)
        setQuantity(1)
    }

    const itemDetailsLink = `/product/${itemDetails.itemCodeUrlSanitized || encodeURIComponent(itemDetails.itemCode)}/${itemDetails.invMastUid}${item.customerPartNumber ? `/${item.customerPartNumber}` : ''}`

    return (
        <>
            {itemDetails && item.itemCode && item.itemCode.startsWith("SERVICE-") ? null : (
                <DetailDivContainer>
                    <AddedModal
                        open={showShowAddedToCartModal}
                        text={'Added to Cart!'}
                        onClose={handleAddedToCart}
                        timeout={900}
                    />
                    <DetailDivCard>
                        <DetailDivCol1>
                            <DetailImgContainer>
                                <ItemImage details={itemDetails} size='thumbnail' postCart={true} link={itemDetailsLink} />
                            </DetailImgContainer>
                        </DetailDivCol1>
                        <DetailDivCol2>
                            <Link to={itemDetailsLink}>
                                <DetailP1>{item.itemCode}</DetailP1>
                            </Link>
                            <DetailTextRow>
                                <CopyToClipboard text={`AHC${item.invMastUid}`}>
                                    <DetailP2>AHC{item.invMastUid}</DetailP2>
                                </CopyToClipboard>
                                {item.customerPartNumber && (
                                    <>
                                        <DetailP2>|</DetailP2>
                                        <CopyToClipboard text={item.customerPartNumber}>
                                            <DetailP2>{item.customerPartNumber}</DetailP2>
                                        </CopyToClipboard>
                                    </>
                                )}
                            </DetailTextRow>
                            {itemDetails?.extendedDesc && <DetailExtendedDescSpan>{itemDetails?.extendedDesc}</DetailExtendedDescSpan>}
                            {item?.extendedDesc && <DetailP2>{item?.extendedDesc}</DetailP2>}
                            <DetailP2>Quantity Ordered: {item.quantityOrdered} / {item.unitOfMeasure}</DetailP2>
                            <DetailP2>Quantity Received: {item.isCancelled ? 'Cancelled' : item.quantityInvoiced}</DetailP2>
                            <DetailP2>Quantity Allocated: {item.quantityAllocated}</DetailP2>
                        </DetailDivCol2>
                        <DetailDivCol2>
                            <DetailP2>Promise Date : {item?.promiseDateOrTBD}</DetailP2>
                            <TrackingP>{item?.trackingNumbers?.length > 0 && <>Tracking Codes: </>}</TrackingP>
                            {hidePurchaseButtons && <TrackingPAlt>{trackingCodes}</TrackingPAlt>}
                            {!hidePurchaseButtons && <TrackingP>{trackingCodes}</TrackingP>}
                            <DetailP2>Order Unit Price: <NumberFormat value={item.unitPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale />{item.unitOfMeasure && <>/{item.unitOfMeasure}</>}</DetailP2>
                            <DetailP2>Order Line Price: <NumberFormat value={item.totalPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale /></DetailP2>
                            {!hidePurchaseButtons && <DetailP2>Current Unit Price: {priceInfo?.unitPrice ? (<NumberFormat value={priceInfo?.unitPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale />) : <>Call for Price</>}</DetailP2>}
                        </DetailDivCol2>
                        <DetailDivCol3>
                            {(!hidePurchaseButtons) && priceInfo && (
                                <Grid container justify="center">
                                    <QuantityInput
                                        quantity={quantity}
                                        unitSize={priceInfo.unitSize}
                                        unitOfMeasure={priceInfo.unitOfMeasure}
                                        roundType={priceInfo.roundType}
                                        handleUpdate={setQuantity}
                                        min='0'
                                    />
                                    {(priceInfo.unitSize > 1) && (
                                        <AirlineChip style={{ marginLeft: '0.5rem', fontSize: '0.9rem' }}>
                                            X {priceInfo.unitSize}
                                        </AirlineChip>
                                    )}
                                </Grid>
                            )}
                            {(!hidePurchaseButtons) && priceInfo && <DetailButtonSmall onClick={handleAddToCart}>Add to Cart</DetailButtonSmall>}
                        </DetailDivCol3>
                    </DetailDivCard>
                    {item.itemNotes && item.itemNotes.length > 0 && <>
                        {item.itemNotes.map((note, idx) =>
                            <DetailFullRow key={idx}>
                                <ShowNoteAlert message={note.note} />
                            </DetailFullRow>
                        )
                        }</>
                    }
                </DetailDivContainer>
            )}

        </>
    )
}