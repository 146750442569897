// const servicesSubItems = [
//     {
//         label: 'Engineered Systems & Assemblies',
//         to: '/pages/services/engineered-systems-and-assemblies'
//     },
//     {
//         label: 'Repair │ Field Service │ Maintenance',
//         to: '/pages/services/repair-field-service'
//     },
//     {
//         label: 'Safety',
//         to: '/pages/services/safety'
//     },
//     {
//         label: 'Efficiency',
//         to: '/pages/services/efficiency'
//     },
//     {
//         label: 'Climate Control Services',
//         to: 'https://info.airlinehyd.com/rittal-climate-control-services',
//         isExternalLink: true
//     },
//     {
//         label: 'Networking Services ',
//         to: 'https://info.airlinehyd.com/networking-services-by-phoenix',
//         isExternalLink: true
//     }
// ]

// const industriesSubItems = [
//     {
//         label: 'Physical Distancing Barriers',
//         to: '/pages/industries/commercial-protective-barriers'
//     },
//     {
//         label: 'Primary Metals',
//         to: '/pages/industries/primary-metals'
//     },
//     {
//         label: 'Power Generation',
//         to: '/pages/industries/power-generation'
//     },
//     {
//         label: 'Marine',
//         to: '/pages/industries/marine'
//     },
//     {
//         label: 'Food Beverage & Packaging',
//         to: '/pages/industries/food-beverage-and-packaging'
//     },
//     {
//         label: 'Plastics & Rubber',
//         to: '/pages/industries/plastics-and-rubber'
//     },
//     {
//         label: 'Construction and Off Road',
//         to: '/pages/industries/construction-off-road'
//     },
//     {
//         label: 'Life Sciences',
//         to: '/pages/industries/life-sciences'
//     },
//     {
//         label: 'Industrial Machinery',
//         to: '/pages/industries/industrial-machinery'
//     },
//     {
//         label: 'Chemical & Water Processing',
//         to: '/pages/industries/chemical-and-water-processing'
//     },
//     {
//         label: 'Pulp & Paper',
//         to: '/pages/industries/pulp-and-paper'
//     },
//     {
//         label: 'Mining & Drilling',
//         to: '/pages/industries/mining-and-drilling'
//     }
// ]

const brandsSubItems = [
	{

		label: 'All Brands',
		to: '/pages/brands'
	},
	{
		label: 'ABB',
		to: '/brands/featured/abb'
	},
	{
		label: 'Banner',
		to: '/brands/featured/banner'
	},
	{
		label: 'Butech',
		to: '/brands/featured/butech'
	},
	{
		label: 'Clippard',
		to: '/brands/featured/clippard'
	},
	{
		label: 'Eaton',
		to: '/brands/featured/eaton'
	},
	{
		label: 'Grace Technologies',
		to: '/pages/brands/grace-technologies/'
	},
	{
		label: 'Haskel',
		to: '/brands/featured/haskel'
	},
	{
		label: 'HYDAC',
		to: '/brands/featured/hydac'
	},
	{
		label: 'icotek',
		to: '/pages/brands/icotek'
	}
	,
	{
		label: 'Lincoln',
		to: '/brands/featured/lincoln'
	},
	{
		label: 'Omron',
		to: '/brands/featured/omron'
	},
	{
		label: 'Oriental Motor',
		to: '/brands/featured/oriental-motor'
	},
	{
		label: 'Paccar',
		to: '/brands/featured/paccar'
	},
	{
		label: 'Parker',
		to: '/brands/featured/parker'
	},
	{
		label: 'Phoenix Contact',
		to: '/brands/featured/phoenix-contact'
	},
	{
		label: 'Rexroth',
		to: '/brands/featured/rexroth'
	},
	{
		label: 'Rittal',
		to: '/brands/featured/rittal'
	},
	{
		label: 'Ross',
		to: '/brands/featured/ross'
	},
	{
		label: 'Schmersal',
		to: '/brands/featured/schmersal'
	},
	{
		label: 'SMC',
		to: '/brands/featured/smc'
	},
	{
		label: 'Turck',
		to: '/brands/featured/turck'
	}
]

// const resourcesSubItems = [
//     {
//         label: 'Apps',
//         to: '/pages/apps'
//     },
//     {
//         label: 'Blog - Technically Speaking',
//         to: 'https://blog.airlinehyd.com/',
//         isExternalLink: true
//     },
//     {
//         label: 'Crossover Corner',
//         to: 'https://info.airlinehyd.com/crossover-corner',
//         isExternalLink: true
//     },
//     {
//         label: 'FAQ',
//         to: 'https://answers.airlinehyd.com/faq',
//         isExternalLink: true
//     },
//     {
//         label: 'Line Cards & Brochures',
//         to: '/pages/resources/linecards'
//     },
//     {
//         label: 'Manufacturer Catalogs',
//         to: '/pages/resources/catalog-request'
//     },
//     {
//         label: 'Knowledge Center',
//         to: '/pages/resources/knowledge-center'
//     },
//     {
//         label: 'SMC Product Configurator',
//         to: 'https://www.airlinehyd.com/srf/SmcUSA',
//         isExternalLink: true
//     },
//     {
//         label: 'Tech Traveler',
//         to: 'https://info.airlinehyd.com/book-tech-traveler',
//         isExternalLink: true
//     },
//     {
//         label: 'Youtube Channel',
//         to: 'https://www.youtube.com/channel/UCdZYpFsi2IES53d5BZr03fw/',
//         isExternalLink: true
//     },
//     {
//         label: 'Webinars',
//         to: '/pages/resources/webinars',
//     },
// ]

const aboutSubItems = [
	{
		label: 'About us',
		to: '/pages/about-us',
	},
	{
		label: 'Careers',
		to: '/pages/about/careers',
	},
	{
		label: 'Company History',
		to: '/pages/about/our-history',
	},
	{
		label: 'Culture corner',
		to: 'https://info.airlinehyd.com/culture-corner',
		isExternalLink: true
	},
	{
		label: 'Events',
		to: '/pages/about/events',
	},
	{
		label: 'Locations',
		to: '/pages/about/locations',
	},
	{
		label: 'Press Room',
		to: '/pages/about/news',
	},
	{
		label: 'Quality Policy',
		to: '/pages/about/quality-policy',
	},
	{
		label: 'Transactional Services',
		to: '/about/transactional-services',
	}
]

// const contactSubItems = [
// 	{
// 		label: 'Contact Us',
// 		to: '/pages/contact/contact-us'
// 	},
// 	{
// 		label: 'Credit Application',
// 		to: '/pages/contact/credit-application'
// 	},
// 	{
// 		label: 'Framing Request',
// 		to: '/pages/contact/framing-request'
// 	},
// 	{
// 		label: 'Government Sales',
// 		to: '/pages/government-sales'
// 	},
// ]

export const headerTabs = categories => [
	{
		label: 'Shop',
		to: '/categories',
		subItems: categories.map(({ name, urlSlug }) => ({
			label: name,
			to: `/categories/${urlSlug}/1`
		}))
	},
	{
		label: 'Services',
		to: '/pages/services',
		// subItems: servicesSubItems
	},
	{
		label: 'Industries',
		to: '/pages/industries',
		// subItems: industriesSubItems
	},
	{
		label: 'Brands',
		to: '/pages/brands',
		subItems: brandsSubItems
	},
	{
		label: 'Resources',
		to: '/pages/resources/resources',
		// subItems: resourcesSubItems
	},
	{
		label: 'About',
		to: '/pages/about',
		subItems: aboutSubItems
	}
]