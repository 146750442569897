import React, { useContext, useState, useEffect, useRef, lazy, Suspense } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import TopAlert from './headerAlertModal'
import Context from '../../setup/context'
import Loader from '../../pageComponents/_common/loader'
import { NavigationItemContainer, DropdownMenu, DropdownMenuItem, MyAccountDropdownMenu, ImgDropdownMenu, ShopImgDropdownMenu, BrandDropdownMenu } from 'pageComponents/_common/dropdown-menu/DropdownMenu'
import { buildSearchString, onWindowResize } from '../../pageComponents/_common/helpers/generalHelperFunctions'
import { useQuery } from '@apollo/client'
import { GET_ROOT_CATEGORIES_HEADER } from 'setup/providerGQL'
import { Button, Menu } from '@mui/material'
import queryString from 'query-string'
import HidePrint from '../../pageComponents/_common/HidePrint'
import OrderDetailModal from '../modals/orderDetailMenu'
import MenuVisibility from 'pageComponents/_common/dropdown-menu/MenuVisibility'
import { useAuth } from 'react-oidc-context'
import { headerTabs } from './headerTabs'

const AdvancedSearch = lazy(() => import('../modals/AdvancedSearch'))
const ImpersonationSearch = lazy(() => import('./impersonationSearch'))
const NotesModal = lazy(() => import('../modals/Notes'))
const BrandsDropdownMenu = lazy(() => import('pageComponents/_common/dropdown-menu/BrandsDropdownMenu'))
const ShopDropdownMenu = lazy(() => import('pageComponents/_common/dropdown-menu/ShopDropdownMenu'))
const AboutDropdownMenu = lazy(() => import('pageComponents/_common/dropdown-menu/AboutDropdownMenu'))
//const ContactDropdownMenu = lazy(() => import('pageComponents/_common/dropdown-menu/ContactDropdownMenu'))
const ResourcesDropdownMenu = lazy(() => import('pageComponents/_common/dropdown-menu/ResourcesDropdownMenu'))

const Nav = styled.div`
	position: ${props => props.location.pathname === '/search' && window.innerWidth < 750 ? 'relative' : '-webkit-sticky'};
	position: ${props => props.location.pathname === '/search' && window.innerWidth < 750 ? 'relative' : (props.disableSticky ? 'inherit' : 'sticky')};
	top: 0;
    align-self: flex-start;
    z-index: 5;
    width: 100%;
`
const NavTop = styled.div`
	display: flex;
	margin: 0 auto;
	padding: 2px 0;
	width: 100%;
	background-color: #535353;
	justify-content: center;
`
const NavBottom = styled.div`
	margin: 0 auto;
	width: 100%;
	background-color: white;
	box-shadow: 0px 3px 4px #dadada;
	height: 48px;
`
const NavContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	align-items: center;
`

const LinkContainer = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	color: black;
	font-size: 14px;
	font-weight: 500;
`
const InputSearch = styled.input`
	width: 260px;
	height: 40px;
	font-size: 15px;
	border-color: #dadada;
	border-top: 1px #dadada solid;
	border-left: 1px #dadada solid;
	border-bottom: 1px #e7e7e7 solid;
	border-right: 0px;
	padding: 0 8px;
	&:focus{
		border-top: 1px #b4b4b4 solid;
		border-left: 1px #b4b4b4 solid;
		border-bottom: 1px #b4b4b4 solid;
	}
	@media (max-width: 365px) {
		width: 215px;
	}
`
const ButtonSearch = styled.button`
	width: 50px;
	height: 40px;
	background-image: linear-gradient(to top left, ${props => props.theme.mainColorBlend},  ${props => props.theme.mainColor});
	color: ${props => props.theme.buttonForegroundColor};
	font-weight: 500;
	border: 0;
	font-size: 14px;
	border-radius: 0 5px 5px 0;
`
const ButtonSearchType = styled.button`
	width: 40px;
	height: 40px;
	background-image: linear-gradient(to top left, #404040, #272727);
	border-radius: 3px 0px 0 3px;
	color: white;
	font-weight: 500;
	border: 0;
	font-size: 14px;
`
const Row = styled.div`
	display: flex;
	align-items: center;
`
const ReverseNavContainer = styled.div`
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: wrap;
	height: 100%;
	width: 100%;
	flex-basis: space-between;
`
const AccountSectionRow = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const UserNameRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-grow: 1;
`
const Puser = styled.p`
	background-image: linear-gradient(to top left, #404040, #333);
	color: #f3f3f3;
	font-size: 11px;
	margin: 0;
	padding: 3px;
	border-radius: 30px;
	font-weight: 600;
`
const PeUser = styled(Puser)`
	background-image: linear-gradient(to top left, #328efc, #133752);
`
const P = styled.p`
	cursor: pointer;
	color: #f3f3f3;
	margin: 0;
	font-size: 14px;
	font-weight: 600;
	padding: 0 5px;
	white-space: nowrap;
`
const A = styled.a`
	cursor: pointer;
	color: #f3f3f3;
	margin: 0;
	font-size: 14px;
	font-weight: 600;
	padding: 0 5px;
	white-space: nowrap;
	&:hover {
		color: #f3f3f3;
	}
`

const AImpersonated = styled(A)`
	font-size: 12px;
`

const Aphone = styled(P)`
	margin: 0;
	color: white;
	white-space: nowrap;
`
const CancelImpersonationButton = styled.button`
	width: 20px;
	background-image: linear-gradient(to top left, ${props => props.theme.mainColorBlend},  ${props => props.theme.mainColor});
	color: ${props => props.theme.buttonForegroundColor};
	font-weight: 500;
	border: 0;
	font-size: 12px;
	border-radius: 5px;
	&:hover {
		color: #f3f3f3;
	}
`
const GrayDiv = styled.div`
	color: gray;
`
const GreenDiv = styled.div`
	color: limegreen;
`
const SearchBarRow = styled.div`
 	display: flex;
 	flex: 1;
 	justify-content: flex-end;
 	@media (max-width: 755px) {
 		justify-content: center;
	}
`

const LoaderContainer = styled.div`
    height: 20px;
    width: 58px;
    position: relative;
    top: -20px;
`
const AirlineLogoImg = styled.img`
    margin-left: 5px;
`
const ContactUsButton = styled.button`
    border: 0;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: #007bff;
	color: white;
	margin-left: 10px;	
`
const ContactUsLink = styled.a`
	color: white;
	text-decoration: none;
	font-weight: 500;
	&:hover{
		color: white;
		text-decoration: none;
	}
`
function ContactUs() {
	return <ContactUsButton><ContactUsLink href="/pages/contact/contact-us">Contact Us</ContactUsLink></ContactUsButton>;
}

function ToMenuItem({ label, to, isExternalLink }) {
	return <DropdownMenuItem key={label} to={to} isExternalLink={isExternalLink}>{label}</DropdownMenuItem>;
}

function AccountSection({ auth, context, location, navigate }) {
	const [isOrderLookupOpen, setIsOrderLookupOpen] = useState(false);

	return <AccountSectionRow>
		<Row style={{ justifyContent: 'center' }}>
			<FontAwesomeIcon icon="phone-alt" color="white" />
			<Aphone href="tel:+18009997378">800-999-7378</Aphone>
		</Row>

		<Row style={{ justifyContent: 'center' }}>
			{auth.isAuthenticated
				? <P onClick={() => void context.signOut() /*Note, sign out isn't complete until the user is redirected back to post_logout_url, in oidc-settings.js */}>Sign Out</P>
				: <P onClick={() => void context.signIn(location?.pathname)}>Sign In</P>
			}

			<P>|</P>

			{auth.isAuthenticated
				? <P onClick={() => navigate('/account/orders')}>My Orders</P>
				: window.innerWidth > 1024 ?
					<P onClick={() => setIsOrderLookupOpen(true)}>Order Lookup</P> :
					<P onClick={() => { navigate("/mobile/orderlookup") }}>Order Lookup</P>
			}

			<OrderDetailModal onClick={() => setIsOrderLookupOpen(true)} open={isOrderLookupOpen} hide={setIsOrderLookupOpen}></OrderDetailModal>

			<P>|</P>

			{auth.isAuthenticated
				? <MyAccountDropdown />
				: <A href="/signup">Request Account</A>
			}

			<P>|</P>

			{context.cart && !context.cartLoading && (
				<Link to='/cart' style={{ textDecoration: 'none' }}>
					{/* <Link to='/cart' style={{ textDecoration: 'none' }}> */}
					<P>Cart({context.cart.length})</P>
				</Link>
			)}
			{(!context.cart || context.cartLoading) && (
				<LoaderContainer>
					<Loader />
				</LoaderContainer>
			)}
		</Row>
	</AccountSectionRow>;
}

function MyAccountDropdown() {
	const [showMyAccountDropdown, setShowMyAccountDropdown] = useState(false);

	return <div
		id="myAccount"
		onMouseEnter={() => setShowMyAccountDropdown(true)}
		onMouseLeave={() => setShowMyAccountDropdown(false)}
	>
		<Link to="/account/dashboard" style={{ textDecoration: 'none' }}>
			<P id="myAccount">My Account</P>
		</Link>

		<MyAccountDropdownMenu className={showMyAccountDropdown ? 'visible' : ''}>
			<DropdownMenuItem to="/account/shopping-lists">Shopping Lists</DropdownMenuItem>
			<DropdownMenuItem to="/pages/contact/contact-us">Request for Quote</DropdownMenuItem>
			<DropdownMenuItem to="/account/dashboard">Account Profile</DropdownMenuItem>
			<DropdownMenuItem to="/account/invoices">Invoices</DropdownMenuItem>
			<DropdownMenuItem to="/account/orders">Orders</DropdownMenuItem>
			<DropdownMenuItem to="/account/open-orders-report">Open Orders Report</DropdownMenuItem>
			<DropdownMenuItem to="/account/quotes">Open Quotes</DropdownMenuItem>
			<DropdownMenuItem to="/account/my-ordered-items">Item Purchase History</DropdownMenuItem>
		</MyAccountDropdownMenu>
	</div>
}

function SearchBar({ context, location, navigate }) {
	const [searchTerm, setSearchTerm] = useState('');
	const [searchAsCustomer, setSearchAsCustomer] = useState(false);

	const handleSearch = () => {
		const parsedQueryString = queryString.parse(location.search);
		const search = searchTerm?.length ? searchTerm : parsedQueryString.searchTerm;
		const hasNonWebChanged = searchAsCustomer !== !!parsedQueryString.nonweb;
		if (search?.length || hasNonWebChanged) navigate(buildSearchString({ searchTerm: search, nonweb: searchAsCustomer }));
	};

	const handleKeyPress = e => e.key === 'Enter' && handleSearch();

	const searchPlaceholder = searchAsCustomer ? '[Non-web Included] Search by Part # or Keyword' : 'Search by Part # or Keyword';

	return <SearchBarRow>
		{context.userInfo?.isAirlineEmployee && (
			<ButtonSearchType onClick={() => setSearchAsCustomer(!searchAsCustomer)}>
				{searchAsCustomer ? <GreenDiv>NW</GreenDiv> : <GrayDiv>NW</GrayDiv>}
			</ButtonSearchType>
		)}

		<InputSearch
			value={searchTerm}
			placeholder={searchPlaceholder}
			onChange={e => setSearchTerm(e.target.value)}
			onKeyPress={handleKeyPress}
		/>

		<ButtonSearch onClick={handleSearch} aria-label="Search icon">
			<FontAwesomeIcon icon="search" color="#f6f6f6" size="lg" />
		</ButtonSearch>
	</SearchBarRow>;
}

function UserNameSection({ context, location, setShowAdvancedModal }) {
	const { userInfo, impersonatedCompanyInfo, cancelImpersonation } = context;
	if (userInfo && !impersonatedCompanyInfo) {
		return (
			<UserNameRow>
				<Puser>
					Hello, {userInfo.preferredName} ({userInfo.companyName} - {userInfo.companyId})
				</Puser>
				{userInfo.isAirlineEngineerUser && (
					<Suspense fallback={<Loader />}>
						<ImpersonationSearch setShowAdvancedModal={setShowAdvancedModal} />
					</Suspense >
				)}
			</UserNameRow>
		);
	} else if ((userInfo && impersonatedCompanyInfo)) {
		return (
			<UserNameRow>
				<PeUser>
					<AImpersonated target='_' href={`${process.env.REACT_APP_WEB_CONNECT_URL}/common/CustomerDetails.aspx?CustomerID=${impersonatedCompanyInfo.CustomerIdP21}&CompanyID=AIRLINE`}>
						<FontAwesomeIcon icon="user-circle" color="#f3f3f3" style={{marginRight:"2px"}} />
						{impersonatedCompanyInfo.CustomerName} - {impersonatedCompanyInfo.CustomerIdP21} [Impersonating]
					</AImpersonated>
					<CancelImpersonationButton title="End Impersonation" onClick={() => cancelImpersonation(location?.pathname)}>
						<FontAwesomeIcon icon="times" color="white" />
					</CancelImpersonationButton>
				</PeUser>
				
				<Suspense fallback={<Loader />}>
					<ImpersonationSearch setShowAdvancedModal={setShowAdvancedModal} />
				</Suspense>
				{impersonatedCompanyInfo?.CustomerMandatoryNotes && (
					<Suspense fallback={<Loader />}>
						<NotesModal notes={impersonatedCompanyInfo.CustomerMandatoryNotes} />
					</Suspense>
				)}
			</UserNameRow>
		);
	} else {
		return null;
	}
}

export default function HeaderComponent(props) {
	const tabContainerRef = useRef(null);
	const tabRefs = useRef([]);
	const location = useLocation();
	const navigate = useNavigate();
	const [categories, setCategories] = useState([]);
	const tabDeclaration = headerTabs(categories);
	const [visibleTabCount, setVisibleTabCount] = useState(tabDeclaration.length);
	const [overflowMenu, setOverflowMenu] = useState(null);
	const [showAdvancedModal, setShowAdvancedModal] = useState(false);

	const context = useContext(Context);
	const [openDropDownMenu, setOpenDropDownMenu] = useState({});
	const auth = useAuth();

	useQuery(GET_ROOT_CATEGORIES_HEADER, {
		onCompleted: data => {
			setCategories(data.getAllRootCategories);
		}
	});

	const calculateTabs = () => {
		const containerRight = tabContainerRef.current && tabContainerRef.current.getBoundingClientRect().right
		const contactUsButtonWidth = 106;
		const widthOfTheComponentsToTheRightOfTheTabs = containerRight < 724 ? 70 : 420 + contactUsButtonWidth; //SearchBar wraps at < 724
		const count = tabRefs.current.reduce((count, tabRight) => {
			if (tabRight <= (containerRight - widthOfTheComponentsToTheRightOfTheTabs)) {
				count += 1
			}
			return count
		}, 0);
		setVisibleTabCount(count);
	};

	useEffect(() => {
		calculateTabs();
		return onWindowResize(calculateTabs);
	}, []);

	const setTabRef = idx => ref => {
		const right = ref && ref.getBoundingClientRect().right;
		if (right) tabRefs.current[idx] = right;
	};

	function ToMenu({ image, label, to, subItems, isExternalLink }, idx) {
		if (window.innerWidth > 1200) {
			if (label === 'Brands') {
				return (
					<MenuVisibility {...{ setOpenDropDownMenu, name: "brands" }} key={label}>
						<NavigationItemContainer to={to} isExternalLink={isExternalLink} text={label} key={label} image={image} ref={setTabRef(idx)}>
							<BrandDropdownMenu>
								{openDropDownMenu.brands ?
									(<Suspense fallback={<Loader />}>
										<BrandsDropdownMenu />
									</Suspense>) : null
								}
							</BrandDropdownMenu>
						</NavigationItemContainer>
					</MenuVisibility>
				)
			}
			if (label === 'Shop') {
				return (
					<MenuVisibility {...{ setOpenDropDownMenu, name: "Shop" }} key={label}>
						<NavigationItemContainer to={to} isExternalLink={isExternalLink} text={label} key={label} image={image} ref={setTabRef(idx)}>
							<ShopImgDropdownMenu>
								{openDropDownMenu.Shop ?
									(<Suspense fallback={<Loader />}>
										<ShopDropdownMenu />
									</Suspense>) : null
								}
							</ShopImgDropdownMenu>
						</NavigationItemContainer>
					</MenuVisibility>
				)
			}
			if (label === 'About') {
				return (
					<MenuVisibility {...{ setOpenDropDownMenu, name: "About" }} key={label}>
						<NavigationItemContainer to={to} isExternalLink={isExternalLink} text={label} key={label} image={image} ref={setTabRef(idx)}>
							<ImgDropdownMenu>
								{openDropDownMenu.About ?
									(<Suspense fallback={<Loader />}>
										<AboutDropdownMenu />
									</Suspense>) : null
								}
							</ImgDropdownMenu>
						</NavigationItemContainer>
					</MenuVisibility>
				)
			}
			if (label === 'Resources') {
				return (
					<MenuVisibility {...{ setOpenDropDownMenu, name: "Resources" }} key={label}>
						<NavigationItemContainer to={to} isExternalLink={isExternalLink} text={label} key={label} image={image} ref={setTabRef(idx)}>
							<ImgDropdownMenu>
								{openDropDownMenu.Resources ?
									(<Suspense fallback={<Loader />}>
										<ResourcesDropdownMenu />
									</Suspense>) : null
								}
							</ImgDropdownMenu>
						</NavigationItemContainer>
					</MenuVisibility>
				)
			}
		}

		return (
			<NavigationItemContainer to={to} isExternalLink={isExternalLink} text={label} key={label} image={image} ref={setTabRef(idx)}>
				<DropdownMenu>
					{subItems && subItems.map(ToMenuItem)}
				</DropdownMenu>
			</NavigationItemContainer>
		)
	}
	const tabComponents = tabDeclaration.map(ToMenu);

	return (
		<Nav location={location} {...props}>
			<HidePrint>
				{context.topAlert?.show && <TopAlert message={context.topAlert.message} close={context.removeTopAlert} />}
				<NavTop>
					<ReverseNavContainer>
						<AccountSection auth={auth} context={context} location={location} navigate={navigate} />
						<UserNameSection context={context} location={location} setShowAdvancedModal={setShowAdvancedModal} />
					</ReverseNavContainer>
				</NavTop>

				<NavBottom>
					<NavContainer ref={tabContainerRef}>
						<Row>
							<Link to="/">
								<AirlineLogoImg src="https://airlinemedia.airlinehyd.com/Airline's_logo/Airline-moving-logo.gif" width="100" alt="AirlineHyd.com" />
							</Link>

							<LinkContainer>
								{tabComponents.slice(0, visibleTabCount)}

								{visibleTabCount < tabDeclaration.length && (
									<Button onClick={e => setOverflowMenu(e.currentTarget)} color="inherit" aria-label="ellipsis-h icon">
										<FontAwesomeIcon icon="ellipsis-h" />
									</Button>
								)}

								<Menu
									disableScrollLock={true}
									onClick={() => setOverflowMenu(null)}
									MenuListProps={{ style: { backgroundColor: '#535353' } }}
									anchorEl={overflowMenu}
									open={!!overflowMenu}
									onClose={() => setOverflowMenu(null)}
								>
									{tabDeclaration
										.slice(visibleTabCount, tabDeclaration.length)
										.map(ToMenuItem)
									}
								</Menu>
							</LinkContainer>
						</Row>
						<ContactUs />
						<SearchBar context={context} location={location} navigate={navigate} />
					</NavContainer>
				</NavBottom>
				{showAdvancedModal && (
					<Suspense fallback={<Loader />}>
						<AdvancedSearch open={showAdvancedModal} onClose={() => setShowAdvancedModal(false)} />
					</Suspense>
				)}
			</HidePrint>
		</Nav>
	);
}

